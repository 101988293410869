// Wait for the DOM content to load
document.addEventListener("DOMContentLoaded", function() {
    // Set the interval for automatic slide change (in milliseconds)
    var interval = 3000; // Change slide every 3 seconds
    
    // Get all the slide radio buttons
    var slides = document.querySelectorAll('input[type="radio"][name="slider"]');
    
    // Function to change to the next slide
    function nextSlide() {
        // Find the currently checked slide
        var currentSlide = document.querySelector('input[type="radio"][name="slider"]:checked');
        
        // Find the index of the current slide
        var currentIndex = Array.from(slides).indexOf(currentSlide);
        
        // Calculate the index of the next slide
        var nextIndex = (currentIndex + 1) % slides.length;
        
        // Check the next slide
        slides[nextIndex].checked = true;
    }
    
    // Start the interval to change slides automatically
    var autoSlide = setInterval(nextSlide, interval);
    
    // Pause auto sliding when hovering over the slider
    var slider = document.getElementById('slider');
    slider.addEventListener('mouseenter', function() {
        clearInterval(autoSlide);
    });
    
    // Resume auto sliding when mouse leaves the slider
    slider.addEventListener('mouseleave', function() {
        autoSlide = setInterval(nextSlide, interval);
    });
});